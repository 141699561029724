<template>
  <div class="px-7 py-4" style="background: #f7f7f7">
    <h3
      v-if="
        this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff' || this.adminAccess=='Player'
      "
    >
      Overview<v-icon color="#2C1963" @click="setFlago()" v-if="!this.tableFlago"
        >mdi-plus</v-icon
      >
      <v-icon
        color="#2C1963"
        @click="setFlagCollpaseo()"
        v-else-if="this.tableFlago"
        style="cursor: pointer"
        >mdi-minus</v-icon
      >
    </h3>
    <v-row
      v-if="
       ( this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff'||this.adminAccess === 'Player') && this.tableFlago
      "
    >
          <v-col cols="9" md="3" class="pl-3">
        <v-autocomplete
          label="Filter By Year"
          outlined
          dense
          v-model="yearFilter"
          :items="yearList"
          class="formFields"
          color="#7253CF"
          @change="getOverviewData()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      v-if="
        (this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff' ||this.adminAccess === 'Player') && this.tableFlago
      "
    >
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(211, 0, 36, 0.12)"
                style="margin-top: 22px"
              >
                <v-icon color="#D30024">mdi-basketball</v-icon>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.total_campaign"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #d30024"
                >
                  Total Campaigns
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2 px-3"
                rounded
                height="40"
                width="40"
                color="rgba(6, 118, 5, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/AdminDashboard/fundCardLogo.png"
                  style="margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-if="kpiData.total_fund_raised"
                  >
                    ${{ kpiData.total_fund_raised.toFixed(2) }}
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #067605"
                >
                  Total Funds Raised
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(231, 111, 0, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/AdminDashboard/studentCardLogo.png"
                  style="margin-left: 2px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p style="margin-left: 10px">
                    {{ kpiData.total_students_registered }}
                    <span v-if="kpiData.total_student > 0"
                      >/ {{ kpiData.total_student }}(
                      {{ kpiData.total_students_registered_percentage }}%
                      )</span
                    >
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #e76f00"
                >
                  Total Students Registered
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/AdminDashboard/videoCardLogo.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.total_videos_watched"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #0085ff"
                >
                  Total Videos Watched
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="
        (this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff'||this.adminAccess === 'Player') && this.tableFlago
      "
    >
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/noofDonation.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.total_donation"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Number of Donations
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/noofDonor.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.total_donor"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Number of Donors
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/totalNumberStudent.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.active_fundraisers"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Number of Active Fundraisers
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/averageDonation.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.average_active_donation"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Average Active Fundraiser Amount
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        (this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff'||this.adminAccess === 'Player') && this.tableFlago
      "
    >
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/averageDonation.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.average_donation"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Average Donation Amount
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/noofShare.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p
                    style="margin-top: 20px; margin-left: 10px"
                    v-text="kpiData.total_shares"
                  ></p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Number of Shares
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
  <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/shareraise.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                 <p style="margin-left: 10px">
                    {{ kpiData.raised_zero }}
                  ({{ kpiData.percent_raised_zero }}%)
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Zero Raised
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/zeroshare.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                  <p style="margin-left: 10px">
                    {{ kpiData.zero_shares }}
                  ({{ kpiData.zero_shares_percentage }}%)
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                  Zero Shares
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
    
        <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-list>
            <v-list-item>
              <v-sheet
                class="text-center pa-2"
                rounded
                height="40"
                width="40"
                color="rgba(0, 133, 255, 0.12)"
                style="margin-top: 22px"
              >
                <v-img
                  width="22px"
                  height="22px"
                  src="@/assets/DistrictCampaign/Dashboard/nosharenoraise.png"
                  style="margin-left: 1px; margin-top: 1px"
                ></v-img>
              </v-sheet>

              <v-list-item-content>
                <v-list-item-title class="title">
                   <p style="margin-left: 10px">
                    {{ kpiData.no_share_no_raise }}
                  ({{ kpiData.no_share_no_raise_percent }}%)
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="CardActionTitle"
                  style="color: #757575"
                >
                 No Share No Raise
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          
        </v-card>
      </v-col>
    </v-row>
    <!-- overview end -->
    <!-- Dashboard Data Table CampaignPanel wise -->
    <campaign-leaderboard
      v-if="
        this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff' || this.adminAccess=='Player'
      "
    ></campaign-leaderboard>
    <!-- School leaderboard table -->
    <school-leaderboard class="d-none"></school-leaderboard>
    <!-- District leaderboard table -->
  <!--  <district-leaderboard
      v-if="
        this.adminAccess == 'Super Admin' ||
        this.adminAccess == 'Sales Representative' ||
        this.adminAccess == 'Event Support Staff' || this.adminAccess=='Player'
      "
    ></district-leaderboard>-->
    <welcome-dashboard v-if="this.adminAccess == 'game'"></welcome-dashboard>
  </div>
</template>

<script>
import {
  API_ADMIN_DASHBOARD_OVERVIEW,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "Dashboard",
  components: {
    CampaignLeaderboard: () =>
      import("./DashboardDataTable/CampaignLeaderboard.vue"),
    SchoolLeaderboard: () =>
      import("@/components/LeaderboardDataTables/SchoolLeaderboard.vue"),
  //  DistrictLeaderboard: () =>
  //    import("./DashboardDataTable/DistrictLeaderboardTable.vue"),
    WelcomeDashboard: () => import("./DashboardDataTable/WelcomeDashboard.vue"),
    //CalendarManagement: () =>
    //   import("../CalendarManagement/CalendarManagement.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      tableFlago:false,
      tableFlag: false,
      kpData: [
        {
          icon: "mdi-video-check",
          iconColor: "#0085FF",
          backColor: "rgba(0, 133, 255, 0.12)",
          title: "# Videos Watched",
          stat: 47898,
        },
      ],
      kpiData: {},
      yearList: [],
      yearFilter: "",
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    getOverviewData() {
      const successHandler = (res) => {
        console.log("in success");
        console.log(res.data);
        this.kpiData = res.data.overview_data;
      };
      const failureHandler = (res) => {
        console.log("in failure");
        console.log(res);
      };
      let formData = {};
      if (this.yearFilter) {
        formData.fiscal_year = this.yearFilter;
      }
      Axios.request_GET(
        API_ADMIN_DASHBOARD_OVERVIEW,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    setFlag() {
      this.tableFlag = true;
    },
    setFlagCollpase() {
      this.tableFlag = false;
    },
       setFlago() {
      this.tableFlago = true;
    },
    setFlagCollpaseo() {
      this.tableFlago = false;
    },
  },
  mounted() {
    if (this.adminAccess === "Super Admin" ) {
      this.setFlago();
    }
    this.getOverviewData();
    this.getYearList();
  },
};
</script>

<style scoped>
.title {
  margin-top: 0px;
  font-size: 10px;
}
.v-card.v-sheet.theme--light {
  height: 118px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
}
.v-list-item__icon {
  max-width: 50px;
  padding: 0px;
}
.v-list-item {
  width: 100%;
  display: flex;
  height: 40px;
}
.v-list-item__title.title {
  margin-top: 25px;
  width: 50px;
}
.v-list-item__title.CardActionTitle {
  font-size: 15px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait) { 
    .col-md-3 {
    flex: 0 0 100%; 
   max-width: 100%; 
}
}
</style>
